import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxMacdChartTemplate from '@/templates/crypto/xxx-macd-chart';

export default function BitcoinMacdChart({ location }: GatsbyPageProps) {
  return (
    <XxxMacdChartTemplate
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      location={location}
      articleId={ArticleList.BITCOIN_MACD_CHART}
    ></XxxMacdChartTemplate>
  );
}
