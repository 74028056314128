import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { diff, ema } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot, { ChartType } from '@/components/charts/crypto-plot';

export default function XxxMacdChartTemplate({
  articleId,
  coinFullName,
  coinAbbreviation,
  token,
  location,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ema12, setEma12] = useState<number[]>();
  const [ema26, setEma26] = useState<number[]>();
  const [macd, setMacd] = useState<number[]>();

  const [ema12Line, setEma12Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    strokeWidth: 1.5,
    title: 'MA12',
  });

  const [ema26Line, setEma26Line] = useState<ChartLine>({
    y: [],
    color: 'orange',
    strokeWidth: 1.5,
    title: 'MA26',
  });

  const [signalLine, setSignalLine] = useState<ChartLine>({
    y: [],
    color: 'orange',
    strokeWidth: 1.5,
    title: 'Signal Line',
  });

  const [macdLine, setMacdLine] = useState<ChartLine>({
    y: [],
    color: 'black',
    strokeWidth: 1.5,
    title: 'MACD',
  });

  const [baseLine, setBaseLine] = useState<ChartLine>({
    y: [],
    color: 'black',
    strokeWidth: 1.5,
    title: 'Base Line',
  });

  useEffect(() => {
    getKLines(token, '1d', 100).then(function (df: DataFrame) {
      setDataFrame(df);
      const _ema12 = ema(df.close, 12);
      const _ema26 = ema(df.close, 26);
      const _macd = diff(_ema26, _ema12);
      const _signal = ema(_macd, 9);
      setEma12(_ema12);
      setEma26(_ema26);
      setMacd(_macd);

      setEma12Line({
        color: 'blue',
        title: 'MA12',
        y: _ema12,
      });

      setEma26Line({
        color: 'orange',
        title: 'MA26',
        y: _ema26,
      });

      setMacdLine({
        color: 'blue',
        title: 'MACD',
        y: _macd,
      });

      setSignalLine({
        color: 'orange',
        title: 'Signal',
        y: _signal,
      });

      setBaseLine({
        color: 'black',
        strokeWidth: 0.5,
        title: 'Base Line',
        y: df.close.slice().fill(0),
        dasharray: '5,5',
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'ema12', 'ema26', 'macd', 'chart']}
      articleId={articleId}
    >
      <section>
        <CryptoPlot
          data={dataFrame}
          lines={[ema12Line, ema26Line]}
          linesFooter={[macdLine, signalLine, baseLine]}
          type={ChartType.Candlestick}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
          withFooter
        />
        <p>
          {coinFullName} moving average convergence divergence (MACD) is a
          trend-following momentum indicator that shows the relationship between
          two moving averages of a coin&apos;s price. The {coinFullName} MACD is
          calculated by subtracting the {coinAbbreviation} 26-period exponential
          moving average (EMA) from the {coinAbbreviation} 12-period EMA.
        </p>
        <p>
          The result of that calculation is the {coinFullName} MACD line. A
          nine-day EMA of the MACD called the &apos;signal line,&apos; is then
          plotted on top of the MACD line, which can function as a trigger for
          buy and sell signals. Crypto traders may buy the token when the{' '}
          {coinFullName} MACD crosses above its signal line and sell—or
          short—the coin when the MACD crosses below the signal line.{' '}
          {coinFullName} moving average convergence divergence (MACD) indicators
          can be interpreted in several ways, but the more common methods are
          crossovers, divergences, and rapid rises/falls.
        </p>
        <h2>{coinFullName} MACD - Calculation</h2>
        <p>
          {coinFullName} MACD is calculated by subtracting the long-term{' '}
          {coinFullName} EMA (26 periods) from the short-term {coinFullName} EMA
          (12 periods). An exponential moving average (EMA) is a type of moving
          average (MA) that places a greater weight and significance on the most
          recent data points.
        </p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={coinFullName + ' MACD'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={macd}
          numberOfRows={10}
        />
        <p>
          The {coinAbbreviation} exponential moving average is also referred to
          as the exponentially weighted moving average. An exponentially
          weighted moving average reacts more significantly to recent price
          changes than a simple moving average (SMA), which applies an equal
          weight to all observations in the period.
        </p>
        <h2>{coinFullName} Moving Average Convergence Divergence - EMA12</h2>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={
            '12-Day Exponential Moving Average (' + coinAbbreviation + ')'
          }
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ema12}
          numberOfRows={10}
        />
        <h2>{coinFullName} Moving Average Convergence Divergence - EMA26</h2>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={
            '26-Day Exponential Moving Average (' + coinAbbreviation + ')'
          }
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ema26}
          numberOfRows={10}
        />
        <h2>Learning From {coinFullName} MACD Baseline</h2>
        <p>
          The {coinFullName} MACD has a positive value (shown as the blue line
          in the chart) whenever the 12-period EMA (indicated by the red line on
          the price chart) is above the 26-period EMA (the blue line in the
          price chart) and a negative value when the 12-period EMA is below the
          26-period EMA. The more distant the {coinFullName} MACD is above or
          below its baseline indicates that the distance between the two EMAs is
          growing.
        </p>
        <p>
          In the following {coinFullName} MACD chart, you can see how the two
          EMAs applied to the price chart correspond to the MACD (blue) crossing
          above or below its baseline (dashed) in the indicator below the price
          chart.
        </p>
        <p>
          {coinFullName} MACD is often displayed with a histogram (see the chart
          below) which graphs the distance between the {coinFullName} MACD and
          its signal line. If the {coinFullName} MACD is above the signal line,
          the histogram will be above the MACD&apos;s baseline. If the MACD is
          below its signal line, the histogram will be below the MACD&apos;s
          baseline. Crypto traders use the MACD&apos;s histogram to identify
          when bullish or bearish momentum is high.
        </p>
        <h2>{coinFullName} MACD vs. Relative Strength</h2>
        <p>
          The {coinFullName} relative strength indicator (RSI) aims to signal
          whether a crypto market is considered to be overbought or oversold in
          relation to recent price levels. The {coinFullName} RSI is an
          oscillator that calculates average price gains and losses over a given
          period of time. The default time period is 14 periods with values
          bounded from 0 to 100.
        </p>
        <p>
          {coinFullName} MACD measures the relationship between two{' '}
          {coinFullName} EMAs, while the {coinFullName} RSI measures price
          change in relation to recent price highs and lows. These two
          indicators are often used together to provide analysts a more complete
          technical picture of a crypto market.
        </p>
        <p>
          These {coinFullName} indicators both measure momentum in a crypto
          market, but, because they measure different factors, they sometimes
          give contrary indications. For example, the {coinFullName} RSI may
          show a reading above 70 for a sustained period of time, indicating a
          market is overextended to the buy-side in relation to recent prices,
          while the Bitocin MACD indicates the market is still increasing in
          buying momentum. Either indicator may signal an upcoming trend change
          by showing divergence from price (price continues higher while the
          indicator turns lower, or vice versa).
        </p>
        <h2>Limitations of {coinFullName} MACD</h2>
        <p>
          One of the main problems with {coinFullName} divergence is that it can
          often signal a possible reversal but then no actual reversal actually
          happens—it produces a false positive. The other problem is that
          {coinFullName} divergence doesn&apos;t forecast all reversals. In
          other words, it predicts too many reversals that don&apos;t occur and
          not enough real price reversals.
        </p>
        <p>
          &apos;False positive&apos; {coinFullName} divergence often occurs when
          the price of a coin moves sideways, such as in a range or triangle
          pattern following a trend. A slowdown in the momentum—sideways
          movement or slow trending movement—of the price will cause the{' '}
          {coinFullName} MACD to pull away from its prior extremes and gravitate
          toward the zero lines even in the absence of a true reversal.
        </p>
      </section>
    </PostTemplate>
  );
}
